import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getDataAPI, postDataAPI } from "../../utils/API";
import { editProperty } from "../../redux/actions/propertyAction";
import CreatePropertyBlockchain from "./DeployNewICO";
import { VIEW_CONTRACT } from "../../utils/config";
import swal from "sweetalert";

//create property component to write all details from form.

const EditProperty = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [blockchaindata, setBlockchainData] = useState("");
	const intialImages = { thumbnails: {}, files: [] };
	const [images, setImages] = useState(intialImages);
	const [deedname, setDeedName] = useState("");
	const [deed, setDeed] = useState("");
	const { property, ico } = useSelector((state) => state);
	if (property.data) {
		var response = property.data;
		if (response.status == 1) {
			response.errors = 0;
			history.push("/");
		}
	}

	const initialState = {
		_id: "",
		title: "",
		address: "",
		description: "",
		contract_address: "",
		tokenPrice: "",
		imageName: "",
		propertyEstimatedValue: "",
		status: "",
		generatic_income: "",
		propertyDeed: "",
		percentageOfOwnership: "",
	};

	const [propertyData, setPropertyData] = useState(initialState);

	const {
		_id,
		title,
		address,
		description,
		contract_address,
		tokenPrice,
		imageName,
		propertyEstimatedValue,
		status,
		generatic_income,
		propertyDeed,
		percentageOfOwnership,
	} = propertyData;

	if (ico.data) {
		var response = ico.data;
		if (response.status == 1) {
			getBlockchain(propertyData);
		}
	}

	const handleChangeFile = (file, set) => {
		if (set == "images") {
			if (file.length === 0) return;
			// discard images which are already uploaded by the user
			const selectimageName = (fileList) => fileList.map((i) => i.name);
			const alreadyUploadedImages = selectimageName(images.files);
			const uniqueImages = [];
			for (let i = 0; i < file.length; i++) {
				if (!alreadyUploadedImages.includes(file[i].name)) {
					const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
					if (file[i].size > maxSizeInBytes) {
						swal(
							"Warning",
							"Image size must not be greater than 1MB",
							"warning"
						);
						return;
					}
					uniqueImages.push(file[i]);
				}
			}
			// build thumbnails
			const newThumbnails = {};
			uniqueImages.forEach((i) => {
				newThumbnails[i.name] = window.URL.createObjectURL(i);
			});

			// update state
			setImages({
				thumbnails: { ...images.thumbnails, ...newThumbnails },
				files: images.files.concat(uniqueImages),
			});
			// setPropertyData({
			// 	...propertyData,
			// 	imageName: propertyData.imageName.concat(selectimageName(uniqueImages)),
			// });
		} else {
			setDeedName(file.name);
			setDeed(file);
			setPropertyData({ ...propertyData, propertyDeed: file.name });
		}
	};

	function removeImage(name) {
		setImages((state) => {
			window.URL.revokeObjectURL(state.thumbnails[name]);
			delete state.thumbnails[name];
			const revisedImageFiles = state.files.filter((f) => f.name !== name);
			return {
				thumbnails: { ...state.thumbnails },
				files: [...revisedImageFiles],
			};
		});
		setPropertyData((state) => {
			return {
				...state,
				imageName: state.imageName.filter((n) => n !== name),
			};
		});
	}

	async function getProperty() {
		var hrefPath = window.location.href;
		var id = hrefPath.split("/")[5];
		getDataAPI("/get_property/" + id).then(function (getProperty) {
			getProperty = getProperty.data[0];
			setPropertyData({ ...propertyData, ...getProperty });
		})
	}
	useEffect(() => {
		getProperty();
	}, []);

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		if (name == "address") {
			document.querySelector(".add_map").setAttribute("src", "https://www.google.com/maps/embed/v1/place?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&q=" + value);
		}
		setPropertyData({ ...propertyData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(editProperty(propertyData, images.files));
	};

	async function getBlockchain(prop) {
		if (prop._id) {
			await getDataAPI("/getPropBlockchainData/" + prop._id).then(function (blockchainData) {
				blockchainData = blockchainData.data[0];
				setBlockchainData(blockchainData);
			});
		}
	}
	useEffect(() => {
		getBlockchain(propertyData);
	}, [propertyData]);

	return (
		<div className="main_content">
			<section>
				<div className="container editPropertyPage">
					<ul className="nav nav-tabs" id="myTab" role="tablist">
						<li className="nav-item" role="presentation">
							<button className="nav-link active" id="create-tab" data-bs-toggle="tab" data-bs-target="#create" type="button" role="tab" aria-controls="create" aria-selected="true">Create Property Blockchain</button>
						</li>
						<li className="nav-item" role="presentation">
							<button className="nav-link" id="edit-tab" data-bs-toggle="tab" data-bs-target="#edit" type="button" role="tab" aria-controls="edit" aria-selected="false">Edit Property</button>
						</li>
					</ul>
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active" id="create" role="tabpanel" aria-labelledby="create-tab">
							{blockchaindata ? (
								<div className="viewBlockchainFirst">
									<a
										href={`${VIEW_CONTRACT}token/${blockchaindata.transactionHash}`}
										// href={`${VIEW_CONTRACT}address/0x61632edaEc7AC0d0FE90188a6C9092c88CFDAc1D#code`}
										target="_blank"
										rel="noreferrer"
										className="crt-ico">
										View Contract on Polygon
									</a>
								</div>
							) : (
								<CreatePropertyBlockchain data={propertyData} />
							)}
						</div>
						<div className="tab-pane fade" id="edit" role="tabpanel" aria-labelledby="edit-tab">
							<div className="create">
								{blockchaindata ? (
									<form onSubmit={handleSubmit}>
										<div className="mb-3">
											<label className="form-label">Title</label>
											<input
												type="text"
												className="form-control"
												name="title"
												value={title}
												placeholder="Title"
												onChange={handleChangeInput}
												readonly
											/>
											<span className="error">
												{property.data ? property.data.errors.title : ""}
											</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Address</label>
											<input
												type="text"
												className="form-control"
												name="address"
												value={address}
												placeholder="Address"
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.address : ""}</span>
											<iframe className="add_map" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&q=${address}`} width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
										</div>
										<div className="mb-3">
											<label className="form-label">Description</label>
											<textarea
												className="form-control"
												type="text"
												name="description"
												value={description}
												placeholder="Description"
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.description : ""}</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Contract Address</label>
											<input
												type="text"
												className="form-control"
												name="contract_address"
												value={contract_address}
												placeholder="Contract Address"
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.contract_address : ""}</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Unit Price(In $)</label>
											<input
												type="text"
												className="form-control"
												name="tokenPrice"
												value={tokenPrice}
												placeholder="Unit Price"
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.tokenPrice : ""}</span>
										</div>
										{/* <div className="mb-3">
										<label className="form-label">Pictures</label>
										<div className="field" align="left">
											<div className="edit-image-upload">
											{imageName.map((img) => (
												<img src={`${img}`}/>
											))}
											</div>
										</div>
									</div> */}
										<div className="mb-3">
											<label className="form-label">Pictures</label>
											<div className="field" align="left">
												{Object.keys(propertyData.imageName)?.length !== 0 && (
													<span className="uploaded-images">
													{Object.entries(propertyData.imageName).map(
														([title, imageName], i) => {
															{
																/** TODO: Style thumbnails */
															}
															return (
																<div key={i} className="thumbnail">
																	<button
																		type="button"
																		onClick={() => removeImage(imageName)}
																	>
																		x
																	</button>
																	<img alt={title} src={imageName} />
																	{/* <span>{title}</span> */}
																</div>
															);
														}
													)}
												</span>
												)}
												{Object.keys(images.thumbnails)?.length !== 0 && (
													<span className="uploaded-images">
														{Object.entries(images.thumbnails).map(
															([title, srcURL], i) => {
																{
																	/** TODO: Style thumbnails */
																}
																return (
																	<div key={i} className="thumbnail">
																		<button
																			type="button"
																			onClick={() => removeImage(title)}
																		>
																			x
																		</button>
																		<img alt={title} src={srcURL} />
																		{/* <span>{title}</span> */}
																	</div>
																);
															}
														)}
													</span>
												)}
												<span className="image-upload">
													<label htmlFor="image-input">
														<img src="/theme/images/upload.png" />
														{""}
													</label>
													<input
														id="image-input"
														type="file"
														name="imageName"
														accept="image/*"
														onChange={(e) =>
															handleChangeFile(e.target.files, "images")
														}
														onClick={(e) => (e.target.value = null)}
														multiple
													/>
												</span>
											</div>
											<span className="error">
												{property.data ? property.data.errors.imageName : ""}
											</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Property Estimated Value(In m2)</label>
											<input
												className="form-control"
												type="text"
												name="propertyEstimatedValue"
												value={propertyEstimatedValue}
												placeholder="Property Estimated Value"
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.propertyEstimatedValue : ""}</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Generetic Income</label>
											<div className="radio_grd">
												<label className="contain">
													<input
														className="form-control"
														type="radio"
														name="generatic_income"
														value="1"
														checked={(generatic_income == 1) ? true : false}
														onChange={handleChangeInput}
													/>
													<span className="checkmark"></span>Yes
												</label>
												<label className="contain">
													<input
														className="form-control"
														type="radio"
														name="generatic_income"
														value="0"
														checked={(generatic_income == 0) ? true : false}
														onChange={handleChangeInput}
													/>
													<span className="checkmark"></span>No
												</label>
											</div>
											<span className="error">{property.data ? property.data.errors.generatic_income : ""}</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Copy of the Propety Deed</label>
											<div className="field" align="left">
												<div className="edit-file-upload">
													<a href={`${propertyDeed}`} target="_blank">
														<img src="/theme/images/samplePdf.png" />
													</a>
												</div>
											</div>
										</div>
										<div className="mb-3">
											<label className="form-label">
												Percentage of Ownership(In %)
											</label>
											<input
												className="form-control"
												placeholder="Percentage of Ownership"
												name="percentageOfOwnership"
												value={percentageOfOwnership}
												onChange={handleChangeInput}
											/>
											<span className="error">{property.data ? property.data.errors.percentageOfOwnership : ""}</span>
										</div>
										<div className="mb-3">
											<label className="form-label">Status</label>
											<select className="form-control" name="status" value={status} onChange={handleChangeInput}>
												<option value="">--Select--</option>
												<option value="1">Active</option>
												<option value="0">Inactive</option>
											</select>
											<span className="error">{property.data ? property.data.errors.status : ""}</span>
										</div>
										<div className="mb-3">
											<button type="submit" className="btn">
												Update Property
											</button>
										</div>
									</form>
								) : (
									<div className="addBlockchainFirst">Please Add Proprty Blockchain first!</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default EditProperty;
