// CONSTANT VARIABLE
export const CHAIN_ID = 80001
export const RPC_URL =  "https://polygon-mumbai-pokt.nodies.app/"
export const CONTRACT_INSTANCE = "0xCC51E75E41288dCa0feE5D6982Bf966DF123ADcC"
export const VIEW_CONTRACT = "https://mumbai.polygonscan.com/"


// export const CHAIN_ID = 97
// export const RPC_URL =  "https://api.avax-test.network/ext/bc/C/rpc/"
// export const CONTRACT_INSTANCE = "0xAD23f6a65055EcF177f58b3ca0120d9d9516F861"
// export const VIEW_CONTRACT = "https://testnet.snowtrace.io/"